@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Thin Italic), url("LabGrotesque-ThinItalic.0fd9a5b3.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Medium Italic), url("LabGrotesque-MediumItalic.f33193d4.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Regular), url("LabGrotesque-Regular.472954df.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Light Italic), url("LabGrotesque-LightItalic.1a2dbe6e.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Medium), url("LabGrotesque-Medium.3ceea624.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Bold), url("LabGrotesque-Bold.c389739c.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Thin), url("LabGrotesque-Thin.c6610637.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Italic), url("LabGrotesque-Italic.97aa8b10.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Black Italic), url("LabGrotesque-BlackItalic.0e738f82.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Bold Italic), url("LabGrotesque-BoldItalic.e9a097f9.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Light), url("LabGrotesque-Light.95ea9dc6.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Lab Grotesque;
  src: local(Lab Grotesque Black), url("LabGrotesque-Black.08c6f285.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/*# sourceMappingURL=index.4191fc49.css.map */
