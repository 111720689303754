@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Thin Italic'), url('LabGrotesque-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Medium Italic'), url('LabGrotesque-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Regular'), url('LabGrotesque-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Light Italic'), url('LabGrotesque-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Medium'), url('LabGrotesque-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Bold'), url('LabGrotesque-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Thin'), url('LabGrotesque-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Italic'), url('LabGrotesque-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Black Italic'), url('LabGrotesque-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Bold Italic'), url('LabGrotesque-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Light'), url('LabGrotesque-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lab Grotesque';
    src: local('Lab Grotesque Black'), url('LabGrotesque-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}